
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'maidanba',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  async created () {
    const code = getCurrentQuery('code')
    const url = getCurrentQuery('url')
    if (url) {
      location.href = `https://creditcardapp.bankcomm.com/oauth2/authorize?client_id=maidanba_to_benlaish&response_type=code&redirect_uri=` + configs.passportRoot + 'partner?redirect=' + url + '&code=' + code + '&targetCode=' + this.targetCode
    } else {
      location.href = `https://creditcardapp.bankcomm.com/oauth2/authorize?client_id=maidanba_to_benlaish&response_type=code&redirect_uri=` + configs.passportRoot + 'partner?redirect=' + encodeURI(`https://m.benlai.com`) + '&code=' + code + '&source=2' + '&targetCode=' + this.targetCode
    }
  }
}
